// CONVENTION formatFoo -> string
import { getAddress } from '@ethersproject/address'

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  try {
    const parsed = getAddress(address)
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
  } catch (error) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
}

export const formatCountdownNumber = (number: string) => {
  if (number != null && Number(number) < 10) {
    return "0" + number
  } else {
    return number
  }
}