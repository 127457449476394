import { ChainId } from "..";

const contractAddresses = {
    boomgala: {
        [ChainId.MAINNET]: "0x4b43C7A309a83E71471218AF76F71E45965C1129",
        [ChainId.MUMBAI]: "0x0e4Da0cf4dd7a3Cb7aA6Bc9882C6C3A7cEeb35d7",
        [ChainId.RINKEBY]: "0x08395af0e1eb3560168458426BC879A36D3ABDBb"
    },
    multicall: {
        [ChainId.MAINNET]: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
        [ChainId.MUMBAI]: '0xe9939e7Ea7D7fb619Ac57f648Da7B1D425832631',
        [ChainId.RINKEBY]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696"
    }
}

export default contractAddresses