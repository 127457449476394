import { nodes } from "../utils/getRpcUrl";

export enum ChainId {
    MAINNET = 1,
    RINKEBY = 4,
    MUMBAI = 80001,
}

export const BASE_SCAN_URLS = {
    [ChainId.MAINNET]: 'https://etherscan.io',
    [ChainId.MUMBAI]: 'https://mumbai.polygonscan.com',
    [ChainId.RINKEBY]: 'https://rinkeby.etherscan.io',
}

export const OPENSEA_URLS = {
    [ChainId.MAINNET]: "https://opensea.io/collection/boomgalaofficial",
    [ChainId.MUMBAI]: "https://testnets.opensea.io/collection/boomgala-v3",
    [ChainId.RINKEBY]: 'https://testnets.opensea.io/collection/boomgala-v3',
}

export const HEX_CHAIN_ID = {
    [ChainId.MAINNET]: "0x1",
    [ChainId.MUMBAI]: "0x13881",
    [ChainId.RINKEBY]: "0x4"
}

export const CHAIN_INFO: { [chainId in ChainId]?: any } = {
    [ChainId.MAINNET]: {
        chainId: HEX_CHAIN_ID[ChainId.MAINNET],
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'Ethereum Mainnet',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: nodes,
        blockExplorerUrls: [BASE_SCAN_URLS[ChainId.MAINNET]]
    },
    [ChainId.RINKEBY]: {
        chainId: HEX_CHAIN_ID[ChainId.RINKEBY],
        chainName: 'Rinkeby Test Network',
        nativeCurrency: {
            name: 'Rinkeby Test Network',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: nodes,
        blockExplorerUrls: [BASE_SCAN_URLS[ChainId.RINKEBY]]
    },
    [ChainId.MUMBAI]: {
        chainId: HEX_CHAIN_ID[ChainId.MUMBAI],
        chainName: 'Polygon Testnet Mumbai',
        nativeCurrency: {
            name: 'Polygon Testnet Mumbai',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: nodes,
        blockExplorerUrls: [BASE_SCAN_URLS[ChainId.MUMBAI]]
    }
}
