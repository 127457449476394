import styled from "styled-components";

export const CardBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    width: "70%",
    padding: "10px",
    boxShadow: "0px 3px 13px rgba(0, 0, 0, 0.15)",
    borderRadius: "39px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.between("lg", "xl")]: {
        width: "85%",
    },

    [theme.breakpoints.between("md", "lg")]: {
        width: "50%",
    },
}))

export const CountDownCardBox = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
    width: "90%",
    padding: "20px",
    boxShadow: "6px 6px 16px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px",
    marginBottom: "15px",
    transform: "translate(0%, -10%)",

    [theme.breakpoints.down("md")]: {
        transform: "none",
        marginBottom: "20px",
        width: "100%",
        padding: "30px",
    },
}))

export const CardImg = styled('img')(({ theme }) => ({
    width: "80%"
}))