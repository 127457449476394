import { useState, useCallback } from "react"
import useEventListener from "./useEventListener"

const useWindowWidth = (width: number) => {
    const widthState = window.innerWidth >= width ? true : false
    const [isVisible, setIsVisible] = useState<boolean>(widthState)

    const updateWindowWidth = useCallback(() => {
        if (window.innerWidth >= width) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [width])

    useEventListener('resize', updateWindowWidth)

    return isVisible
}

export default useWindowWidth