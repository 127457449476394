import addresses from "../config/constants/addresses";
import { ChainId } from "../config";

export const getMulticallAddress = (chainId: ChainId) => {
    return addresses.multicall[chainId]
}

export const getBoomgalaAddress = (chainId: ChainId) => {
    return addresses.boomgala[chainId]
}
