import { ethers } from 'ethers'
import { getMulticallAddress } from "./addressHelpers"
import { simpleRpcProvider } from './providers'
import MultiCallAbi from "../config/abis/multicall.json"

type MultiCallResponse<T> = T | null

const chainId = Number(process.env.REACT_APP_CHAIN_ID)

export interface Call {
    address: string // Address of the contract
    name: string // Function name on the contract (example: balanceOf)
    params?: any[] // Function params
}

interface MulticallOptions {
    requireSuccess?: boolean
}

const multicall = async <T = any>(
    abi: any[],
    calls: Call[],
    options: MulticallOptions = { requireSuccess: true },
): Promise<MultiCallResponse<T>> => {
    const { requireSuccess } = options
    const multi = new ethers.Contract(getMulticallAddress(chainId), MultiCallAbi, simpleRpcProvider)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls.map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])

    const returnData = await multi.callStatic.tryAggregate(requireSuccess, calldata)

    const res = returnData.map((call: any, i: any) => {
        const [result, data] = call
        return result ? itf.decodeFunctionResult(calls[i].name, data) : null
    })

    return res
}

export default multicall